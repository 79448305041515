import helpers from '@/dc-it/services/helpers'
import Field from '../types/Field'
import Model from './Model'

export default class Commission extends Model {
    endpoint = 'commissions';

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('min_amount', 'Monto mínimo', 'number').applyMask(value => `$ ${helpers.numberFormat(value)}`),
      new Field('max_amount', 'Monto máximo', 'number').applyMask(value => `$ ${helpers.numberFormat(value)}`),
      new Field('commission_percent', 'Porcentaje de comisión', 'number').applySuffix('%'),
    ]

    clone = () => Commission;
}
